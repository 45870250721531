import { DEFAULT_STORE_CODE } from '@configs/defaults/store-codes';

export const STORE_CODES_WHITELIST = [DEFAULT_STORE_CODE];

const getFaqBlocksIds = () =>
    Array.from(
        { length: 15 },
        (_, index) => `psb_loyalty_club_faq_${index + 1}`
    );

export const LOYALTY_CLUB_FAQ_BLOCKS_IDS = getFaqBlocksIds();

export const calculationsTypes = {
    moneyToPoints: 'MONEY_TO_POINTS_CONVERSION_BASIC',
    additionalPointsForProductParameter:
        'ADDITIONAL_POINTS_FOR_PRODUCT_PARAMETER',
    additionalPointsForOrderParameter: 'ADDITIONAL_POINTS_FOR_ORDER_PARAMETER',
};

export const historyTypes = {
    orderCancellation: 'giving_back_burned_points_due_to_order_cancellation',
    orderReturned: 'taking_back_earned_points_due_to_order_return',
    order: 'earned_points_for_order',
    burningPointsForVoucher: 'burning_points_for_voucher',
    burningPointsForOrder: 'burning_points_for_order',
    expiredPoints: 'subtraction_of_expired_points',
    manualSubtraction: 'manual_subtraction',
    manualAddition: 'manual_addition',
    splitOfTakingBackEarnedPointsDueToOrderReturn:
        'split_of_taking_back_earned_points_due_to_order_return',
    birthdayGift: 'birthday_gift',
    pointsForJoiningProgram: 'points_for_joining_program',
    pointsForBirthDateProvided: 'points_for_birth_date_provided',
};

export const LOYALTY_CLUB_QUERY_TAB_KEY = 'loyaltyClubTab';
export const LOYALTY_CLUB_TABS = {
    history: 'history',
    points: 'points',
};

export const MOBILE_APPLICATION_URL = 'https://eobweb.onelink.me/0B1c/q5pus79z';
export const MOBILE_APPLICATION_MODIVO_URL =
    'https://modivo-app-banner.onelink.me/lSS9/loyalty';

export const memberStatuses = {
    ACTIVE: 'active',
    WAITING_FOR_CONSENT: 'waiting_for_consent',
    LEFT: 'left',
    BLOCKED: 'blocked',
};

export const mobileAppEvents = {
    goToRegister: 'goToRegister',
    signOffFromLoyaltyClub: 'signOffFromLoyaltyClub',
    signInToLoyaltyClub: 'signInToLoyaltyClub',
    startShopping: 'startShopping',
    contactUs: 'contactUs',
};

export const LOYALTY_FRIENDS_AND_FAMILY_TEST_COOKIE_NAME =
    'loyalty_friends_and_family_test';
export const LOYALTY_FRIENDS_AND_FAMILY_TEST_QUERY_KEY =
    'loyaltyClubFriendsAndFamily';
