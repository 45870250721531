import { STORE_CONTEXT } from '@configs/context';
import {
    ACCOUNT_VERIFICATION_STATES,
    CHANNEL_TYPE_EMAIL,
} from '@configs/account-verification';
import {
    BAD_REQUEST_HTTP_CODE,
    GONE_HTTP_CODE,
    NO_CONTENT_HTTP_CODE,
    NOT_FOUND_HTTP_CODE,
} from '@configs/http-codes';
import {
    MODAL_ACCOUNT_VERIFICATION,
    MODAL_ACCOUNT_VERIFICATION_TYPE,
} from '@configs/modals';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import {
    getAvatarHashFromCookies,
    getAvatarIdFromCookies,
    setAvatarIdInCookie,
} from '@assets/avatar';
import { asyncDelay } from '@assets/async-delay';

import { types } from './mutations';

const MODULE_NAME = 'avatar';

export default {
    async createIdForGuest({ rootGetters, commit, state }, timeout = null) {
        if (state.isCreatingId) {
            return;
        }

        commit(types.SET_IS_CREATING_ID, true);

        const storeCode = rootGetters['config/storeCode'];
        const hash = getAvatarHashFromCookies(this.$cookies);

        const promises = [
            this.$services.avatar.createIdForGuest({
                hash,
                storeContext: STORE_CONTEXT,
                websiteContext: storeCode,
            }),
        ];

        if (timeout) {
            promises.push(asyncDelay(timeout));
        }

        const response = await Promise.race(promises);

        const {
            data = null,
            error = `ERR_CONNECTION_TIMED_OUT - ${timeout}ms`,
        } = response || {};

        const { id: avatarId } = data || {};

        if (avatarId) {
            setAvatarIdInCookie(this.$cookies, avatarId);
            commit(types.SET_AVATAR_ID, avatarId);
            commit(types.SET_SHOULD_CONNECT_CLIENT, true);
        } else {
            this.$errorHandler.captureStoreMessage(
                MODULE_NAME,
                'Avatar unsuccessful create ID for guest',
                {
                    [ERROR_ACTION_TAG_NAME]: 'avatar.createIdForGuest',
                },
                {
                    storeCode,
                    storeContext: STORE_CONTEXT,
                    error: JSON.stringify(error),
                    isServerSide: process.server,
                }
            );
        }

        commit(types.SET_IS_CREATING_ID, false);
    },

    async connectClient({ rootGetters, rootState, commit, state }) {
        const { isConnectingClient, isCreatingId } = state;

        if (
            isConnectingClient ||
            isCreatingId ||
            !rootGetters['customer/isLoggedIn']
        ) {
            return;
        }

        const avatarId = getAvatarIdFromCookies(this.$cookies);
        const { accessToken } = rootState.customer;

        if (!avatarId || !accessToken) {
            return;
        }

        commit(types.SET_IS_CONNECTING_CLIENT, true);

        const { data, error } = await this.$services.avatar.connectClient(
            avatarId
        );

        const { status } = data || {};

        if (status !== 'success' || error) {
            this.$errorHandler.captureStoreMessage(
                MODULE_NAME,
                'Avatar unsuccessful connect client',
                {
                    [ERROR_ACTION_TAG_NAME]: 'avatar.connectClient',
                },
                {
                    status,
                    error: JSON.stringify(error),
                }
            );
        }

        commit(types.SET_SHOULD_CONNECT_CLIENT, false);
        commit(types.SET_IS_CONNECTING_CLIENT, false);
    },

    async createAndConnectId({ dispatch }) {
        const avatarId = getAvatarIdFromCookies(this.$cookies);

        if (!avatarId) {
            await dispatch('createIdForGuest');
        }

        await dispatch('connectClient');
    },

    async setIsInviteFriendFeatureEnabled({ commit }, isEnabled) {
        commit(types.SET_IS_INVITE_FRIEND_FEATURE_ENABLED, isEnabled);
    },

    async setIsInviteFriendFeaturePaused({ commit }, isPaused) {
        commit(types.SET_IS_INVITE_FRIEND_FEATURE_PAUSED, isPaused);
    },

    async getInviteFriendToken() {
        const {
            data,
            error,
        } = await this.$services.avatar.getInviteFriendToken();

        if (error) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                error,
                {
                    [ERROR_ACTION_TAG_NAME]: 'avatar.getInviteFriendToken',
                },
                {
                    error: JSON.stringify(error),
                }
            );
        }

        return data?.referralToken || '';
    },

    async createInviteFriendToken() {
        const {
            data,
            error,
        } = await this.$services.avatar.createInviteFriendToken();

        if (error) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                error,
                {
                    [ERROR_ACTION_TAG_NAME]: 'avatar.createInviteFriendToken',
                },
                {
                    error: JSON.stringify(error),
                }
            );
        }

        return data?.referralToken || '';
    },

    async checkInviteFriendToken({ rootGetters }, inviteToken) {
        const storeCode = rootGetters['config/storeCode'];

        const {
            data,
            error,
        } = await this.$services.avatar.checkInviteFriendToken({
            inviteToken,
            storeContext: STORE_CONTEXT,
            websiteContext: storeCode,
        });

        if (error) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                error,
                {
                    [ERROR_ACTION_TAG_NAME]: 'avatar.checkInviteFriendToken',
                },
                {
                    error: JSON.stringify(error),
                }
            );
        }

        return data?.isExist || false;
    },

    setIsInviteFriendRegisterPageMounted({ commit }, isMounted) {
        commit(types.SET_IS_INVITE_FRIEND_REGISTER_PAGE_MOUNTED, isMounted);
    },

    async getInviteFriendVouchersHistory({ rootState }) {
        const { accessToken } = rootState.customer;

        if (!accessToken) {
            return [];
        }

        const {
            data,
            error,
        } = await this.$services.avatar.getVouchersHistory();

        if (error) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                error,
                {
                    [ERROR_ACTION_TAG_NAME]:
                        'avatar.getInviteFriendVouchersHistory',
                },
                {
                    error: JSON.stringify(error),
                }
            );
        }

        return data || [];
    },

    async sendMagicLink({ rootGetters }, email) {
        const storeCode = rootGetters['config/storeCode'];

        let result = true;

        const { error } = await this.$services.avatar.sendMagicLink({
            email,
            storeContext: STORE_CONTEXT,
            websiteContext: storeCode,
        });

        if (error) {
            result = false;

            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                error,
                {
                    [ERROR_ACTION_TAG_NAME]: 'avatar.sendMagicLink',
                },
                {
                    storeCode,
                    storeContext: STORE_CONTEXT,
                    error: JSON.stringify(error),
                }
            );
        }

        return result;
    },

    async checkAccountVerificationStatus({ rootState, commit }) {
        const { email = null } = rootState.customer.customerData || {};
        const hasAccessToken = !!rootState.customer.accessToken;

        if (!email || !hasAccessToken) {
            commit(types.SET_ACCOUNT_VERIFICATION_STATUS, null);

            return null;
        }

        const { NOT_EXIST, COMPLETE, PENDING } = ACCOUNT_VERIFICATION_STATES;

        const payload = {
            channelType: CHANNEL_TYPE_EMAIL,
            channelId: email,
        };

        const {
            data,
            error,
            status,
        } = await this.$services.avatar.checkAccountVerificationStatus(payload);

        if (status === NOT_FOUND_HTTP_CODE) {
            commit(types.SET_ACCOUNT_VERIFICATION_STATUS, NOT_EXIST);

            return NOT_EXIST;
        }

        if (status >= BAD_REQUEST_HTTP_CODE) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                error,
                {
                    [ERROR_ACTION_TAG_NAME]:
                        'avatar.checkAccountVerificationStatus',
                },
                {
                    error: JSON.stringify(error),
                }
            );
            commit(types.SET_ACCOUNT_VERIFICATION_STATUS, null);

            return null;
        }

        const verificationStatus =
            data?.confirmedAt !== null ? COMPLETE : PENDING;

        commit(types.SET_ACCOUNT_VERIFICATION_STATUS, verificationStatus);

        return verificationStatus;
    },

    setAccountVerificationStatus({ commit }, status) {
        commit(types.SET_ACCOUNT_VERIFICATION_STATUS, status);
    },

    async sendAccountVerification({
        rootGetters,
        rootState,
        dispatch,
        commit,
    }) {
        if (!rootGetters['customer/isLoggedIn']) {
            return;
        }

        const verificationStatus = await dispatch(
            'checkAccountVerificationStatus'
        );

        const { PENDING, COMPLETE, NOT_EXIST } = ACCOUNT_VERIFICATION_STATES;

        const showErrorMessage = () => {
            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t(
                        'Something went wrong. Please try again in a moment.'
                    ),
                },
                { root: true }
            );
        };

        if (!verificationStatus) {
            showErrorMessage();

            return false;
        }

        if (verificationStatus === COMPLETE) {
            dispatch(
                'messages/addSuccessMessage',
                {
                    text: this.app.i18n.t(
                        'Your email address has already been confirmed.'
                    ),
                },
                { root: true }
            );

            return false;
        }

        const { email } = rootState.customer.customerData;

        const ACTIONS = {
            [NOT_EXIST]: payload =>
                this.$services.avatar.createAccountVerification(payload),
            [PENDING]: payload =>
                this.$services.avatar.resendAccountVerification(payload),
        };

        const chosenAccountVerificationAction = ACTIONS[verificationStatus];

        if (!chosenAccountVerificationAction) {
            return false;
        }

        const { error, status } = await chosenAccountVerificationAction({
            channelType: CHANNEL_TYPE_EMAIL,
            channelId: email,
        });

        if (!error) {
            commit(types.SET_ACCOUNT_VERIFICATION_STATUS, PENDING);

            return true;
        }

        showErrorMessage();

        this.app.$errorHandler.captureStoreError(
            MODULE_NAME,
            error,
            {
                [ERROR_ACTION_TAG_NAME]: 'avatar.sendAccountVerification',
            },
            {
                error: JSON.stringify(error),
                status,
                verificationStatus,
            }
        );

        return false;
    },

    async confirmAccountVerification(_context, token) {
        const { COMPLETE, ERROR } = ACCOUNT_VERIFICATION_STATES;

        if (!token) {
            return ERROR;
        }

        const {
            error,
            status,
        } = await this.$services.avatar.confirmAccountVerification(token);

        switch (status) {
            case NO_CONTENT_HTTP_CODE:
                return COMPLETE;
            case GONE_HTTP_CODE:
                return ERROR;
            default:
                this.app.$errorHandler.captureStoreError(
                    MODULE_NAME,
                    error,
                    {
                        [ERROR_ACTION_TAG_NAME]:
                            'avatar.confirmAccountVerification',
                    },
                    {
                        error: JSON.stringify(error),
                        status,
                    }
                );

                return ERROR;
        }
    },

    clearAccountVerificationData({ commit }) {
        commit(types.SET_ACCOUNT_VERIFICATION_STATUS, null);
    },

    async showAccountVerificationIfNeeded({ getters, rootGetters }) {
        if (
            !getters.canVerifyAccount ||
            !rootGetters['config/isAccountVerificationEnabled']
        ) {
            return false;
        }

        await this.$modals.open(MODAL_ACCOUNT_VERIFICATION, {
            [MODAL_ACCOUNT_VERIFICATION_TYPE]: ACCOUNT_VERIFICATION_STATES.SEND,
        });

        return true;
    },

    async checkEmailExist({ rootGetters }, email) {
        try {
            const storeCode = rootGetters['config/storeCode'];

            const { data, error } = await this.$services.avatar.checkEmailExist(
                {
                    websiteContext: storeCode,
                    storeContext: STORE_CONTEXT,
                    email,
                }
            );

            if (!error) {
                return data?.exists || false;
            }

            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                {
                    [ERROR_ACTION_TAG_NAME]: 'avatar.checkEmailExist',
                },
                {
                    error: JSON.stringify(error),
                }
            );
        } catch (error) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                error,
                {
                    [ERROR_ACTION_TAG_NAME]: 'avatar.checkEmailExist',
                },
                {
                    error: JSON.stringify(error),
                }
            );
        }

        return false;
    },

    async assignClientToOrder({ state }, { orderNumber, email }) {
        try {
            const avatarId =
                getAvatarIdFromCookies(this.$cookies) || state.avatarId;

            const {
                error,
                status,
            } = await this.$services.avatar.assignClientToOrder({
                orderNumber,
                email,
                instanceId: avatarId,
            });

            if (!error) {
                return status === NO_CONTENT_HTTP_CODE;
            }

            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                {
                    [ERROR_ACTION_TAG_NAME]: 'avatar.assignClientToOrder',
                },
                {
                    error: JSON.stringify(error),
                }
            );
        } catch (error) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                error,
                {
                    [ERROR_ACTION_TAG_NAME]: 'avatar.assignClientToOrder',
                },
                {
                    error: JSON.stringify(error),
                }
            );
        }

        return false;
    },
};
