import {
    CHECKOUT_CART_PAGE_NAME,
    CHECKOUT_PAGE_NAME,
    CHECKOUT_REPAYMENT_NAME,
    CHECKOUT_SUCCESS_PAGE_NAME,
    CONTACT_PAGE_NAME,
    CMS_PAGE_NAME,
    NEWSLETTER_PAGE_NAME,
} from '@router/names';

import {
    MESSAGE_CONFIRM_NEWSLETTER_FOOTER_POP_UP,
    MESSAGE_CONFIRM_NEWSLETTER_AUTOMATIC_POP_UP,
    MESSAGE_CONFIRM_NEWSLETTER_WISHLIST_POP_UP_WISHLIST,
    MESSAGE_CONFIRM_NEWSLETTER_WISHLIST_POP_UP_PRODUCT,
    MESSAGE_CONFIRM_NEWSLETTER_WISHLIST_POP_UP_LISTING,
    MESSAGE_CONFIRM_NEWSLETTER_WISHLIST_POP_UP_HOVER,
    WISHLIST_POPUP_WISHLIST,
    WISHLIST_POPUP_PRODUCT,
    WISHLIST_POPUP_HOVER,
    WISHLIST_POPUP_LISTING,
    AUTOMATIC_POP_UP,
    FOOTER_POP_UP,
    HEADER_POP_UP,
    WISHLIST_PRODUCT,
    WISHLIST_LISTING,
} from '@analytics-modules/client/types/Labels';

import {
    NEWSLETTER_RELEASER_FOOTER,
    NEWSLETTER_RELEASER_AUTO,
    NEWSLETTER_RELEASER_HEADER,
    NEWSLETTER_RELEASER_WISHLIST_PRODUCT,
    NEWSLETTER_RELEASER_WISHLIST,
    NEWSLETTER_RELEASER_WISHLIST_HOVER,
    NEWSLETTER_RELEASER_WISHLIST_LISTING,
} from '@configs/modals';

export const PSB_NEWSLETTER_TERMS = 'psb_newsletter_terms';
export const PSB_NEWSLETTER_TERMS_SHORT = 'psb_newsletter_terms_short';

export const PSB_NEWSLETTER_SUBSCRIBE_SUCCESS =
    'psb_newsletter_subscribe_success';
export const PSB_NEWSLETTER_SUBSCRIBE_FAILED =
    'psb_newsletter_subscribe_failed';
export const PSB_NEWSLETTER_UNSUBSCRIBE_SUCCESS =
    'psb_newsletter_unsubscribe_success';
export const PSB_NEWSLETTER_UNSUBSCRIBE_FAILED =
    'psb_newsletter_unsubscribe_failed';

export const NEWSLETTER_CMS_BLOCKS_IDS = [
    PSB_NEWSLETTER_TERMS,
    PSB_NEWSLETTER_TERMS_SHORT,
];

export const NEWSLETTER_SUBSCRIBE_PAGE_BLOCKS_IDS = [
    PSB_NEWSLETTER_SUBSCRIBE_SUCCESS,
    PSB_NEWSLETTER_SUBSCRIBE_FAILED,
];

export const NEWSLETTER_UNSUBSCRIBE_PAGE_BLOCKS_IDS = [
    PSB_NEWSLETTER_UNSUBSCRIBE_SUCCESS,
    PSB_NEWSLETTER_UNSUBSCRIBE_FAILED,
];

export const UNSUBSCRIBE_CODE_QUERY_PARAM_NAME = 'code';

export const NUMBER_OF_PAGE_VIEWS_TO_SHOW_NEWSLETTER = 3;
export const NEWSLETTER_MODAL_VIEWS_LIMIT = 5;

export const MODAL_NEWSLETTER_EXCLUDED_ROUTES = [
    CHECKOUT_CART_PAGE_NAME,
    CHECKOUT_PAGE_NAME,
    CHECKOUT_REPAYMENT_NAME,
    CHECKOUT_SUCCESS_PAGE_NAME,
    CONTACT_PAGE_NAME,
    CMS_PAGE_NAME,
    NEWSLETTER_PAGE_NAME,
];

export const LOCALES_EXCLUDED_FROM_NEWSLETTER_PROMOTION = ['hu_HU', 'lt_LT'];

export const NEWSLETTER_CONFIRMATION_SOURCE_MAP = {
    d: 'doi',
    dr: 'doi_reminder',
    sdr: 'synerise_doi_reminder',
};

export const NEWSLETTER_POPUP_EVENT_LABELS_MAP = {
    [NEWSLETTER_RELEASER_FOOTER]: FOOTER_POP_UP,
    [NEWSLETTER_RELEASER_AUTO]: AUTOMATIC_POP_UP,
    [NEWSLETTER_RELEASER_WISHLIST]: WISHLIST_POPUP_WISHLIST,
    [NEWSLETTER_RELEASER_WISHLIST_HOVER]: WISHLIST_POPUP_HOVER,
    [NEWSLETTER_RELEASER_WISHLIST_PRODUCT]: WISHLIST_POPUP_PRODUCT,
    [NEWSLETTER_RELEASER_WISHLIST_LISTING]: WISHLIST_POPUP_LISTING,
    [NEWSLETTER_RELEASER_HEADER]: HEADER_POP_UP,
};

export const MESSAGE_CONFIRM_NEWSLETTER_EVENT_LABELS_MAP = {
    [NEWSLETTER_RELEASER_FOOTER]: MESSAGE_CONFIRM_NEWSLETTER_FOOTER_POP_UP,
    [NEWSLETTER_RELEASER_AUTO]: MESSAGE_CONFIRM_NEWSLETTER_AUTOMATIC_POP_UP,
    [NEWSLETTER_RELEASER_WISHLIST]: MESSAGE_CONFIRM_NEWSLETTER_WISHLIST_POP_UP_WISHLIST,
    [NEWSLETTER_RELEASER_WISHLIST_HOVER]: MESSAGE_CONFIRM_NEWSLETTER_WISHLIST_POP_UP_HOVER,
    // eslint-disable-next-line max-len
    [NEWSLETTER_RELEASER_WISHLIST_PRODUCT]: MESSAGE_CONFIRM_NEWSLETTER_WISHLIST_POP_UP_PRODUCT,
    // eslint-disable-next-line max-len
    [NEWSLETTER_RELEASER_WISHLIST_LISTING]: MESSAGE_CONFIRM_NEWSLETTER_WISHLIST_POP_UP_LISTING,
    [NEWSLETTER_RELEASER_HEADER]: HEADER_POP_UP,
};

export const NEWSLETTER_TOOLTIP_EVENT_LABELS_MAP = {
    [NEWSLETTER_RELEASER_WISHLIST_PRODUCT]: WISHLIST_PRODUCT,
    [NEWSLETTER_RELEASER_WISHLIST_LISTING]: WISHLIST_LISTING,
};

const MOBILE_NEWSLETTER_MODAL_TEST_NAME = 'eob_all_mobile_newsletter_modal';
const MOBILE_NEWSLETTER_MODAL_TEST_ON = 'on';

export const isMobileModalNewsletterTestOn = $abTests =>
    $abTests?.getVariant(MOBILE_NEWSLETTER_MODAL_TEST_NAME) ===
    MOBILE_NEWSLETTER_MODAL_TEST_ON;

const MOBILE_NEWSLETTER_TOOLTIP_TEST_NAME = 'eob_all_mobile_newsletter_tooltip';
const MOBILE_NEWSLETTER_TOOLTIP_TEST_ON = 'on';

export const isMobileNewsletterTooltipTestOn = $abTests =>
    $abTests?.getVariant(MOBILE_NEWSLETTER_TOOLTIP_TEST_NAME) ===
    MOBILE_NEWSLETTER_TOOLTIP_TEST_ON;
